@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
	animation: App-logo-spin infinite 1s linear;
}

.app-bar {
	background-color: transparent;
	/* background: linear-gradient(0deg, transparent 5%,  transparent 15%, transparent 69%, #00FFD169 70%, #FF6BDE69 71%, transparent 75%, transparent 100%);
	animation: gradientAnimation 10s linear infinite; */
}

@keyframes gradientAnimation {
	0% {
		background-size: 30% 100%;
		background-position: 0% 0%;
	}
	36% {
		box-shadow: 0 5px 0px rgba(0,0,0,0.7);
	}
	50% {
		background-size: 200% 230%;
		background-position: 100% 0%;
	}
	69% {
		box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
	}
	100% {
		background-size: 30% 100%;
		background-position: 0% 0%;
	}
}
.background-splash-base {

	background-image: radial-gradient(circle, #2a0240 10%, transparent 10%);
	background-size: 1% 1%;
	background-repeat: no-repeat;
	background-position: -100% -100%;
	transition: background-size 0.5s ease-in-out, background-position 0.5s ease-in-out;
}
.background-splash
 {
	background-image: radial-gradient(circle, #2a0240 10%, transparent 10%);
	background-size: 100% 100%;
	background-repeat: no-repeat;

	animation: splash .69s ease-in-out forwards;

}

.background-splash .mock {
	animation: splash .69s ease-in-out forwards;
}


@keyframes splash {
	0% {
		background-size: 100% 100%;
		background-position: 31% 31%;
		color: inherit;
	}
	100% {

		background-size: 1000% 1000%;
		background-position: center;
		color: #fff;
	}
}

.text-cutout {
	background-color: #2a0240;
	text-transform: uppercase;
	color: white;
	font-size: 50px;
	font-weight: bold;
	margin: 0 auto;
	padding: 7px 15px;
	mix-blend-mode: darken;
	display: inline-block;
}
.text-cutout-w {
	color: #ff08ec;
	text-transform: uppercase;
	font-weight: bold;
	
	display: inline-block;
	background-image: linear-gradient(90deg, #fe026f 6%, #fd5b46 33%, #dc0eca 69%, #dc0eca 97%, #fe026f 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	transition: background-size 0.3s ease-in-out, background-position 0.3s ease-in-out;
	background-position: 0% 0%;
}

.text-cutout-container {
	margin: 0 auto;
	padding: 7px 15px;
	background-color: #2a0240;
	display: inline-block;
	transition: background-color 0.3s ease-in-out;
}

.text-cutout-container.hoverable:hover {
	background-color: #f3dbff;

}
.text-cutout-container.hoverable:hover .text-cutout-w,
.text-cutout-container.animate .text-cutout-w
{
	background-position: 0% 0%;
	background-size: 150% 150%;
	animation: pulsateBackground 5s ease-in-out infinite alternate;
}

@keyframes pulsateBackground {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 170% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}




.radial-hover:hover {
	background-image: radial-gradient(circle, rgba(89,248,255,0.801073774236257) 0%, rgba(255,255,255,0) 69%);
	background-position: center;
}


.section-large-background {
}

.glow {
	box-shadow: 0 0 10px #fff;
	animation: glow 1s ease-in-out infinite alternate;
	border-radius: 10px;
}
.glow-unr {
	box-shadow: 0 0 10px #fff;
	animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
	from {
		box-shadow: 0 0 10px magenta;
	}
	to {
		box-shadow: 0 0 20px cyan;
	}
}

.tooltip {
	background-color: red;
	color: tomato;
}


.panorama_ad {
	background-image: linear-gradient(0deg, rgba(16,1,14,0.99) 6%, rgba(37,35,35,1) 33%, rgba(2,10,10,1) 69%);
	background-size: cover;
	padding: 40px 0 50px 0;
}